<template>
  <div class="bg-lovin h-100">
    <div class="container h-100">
      <h1 class="mt-2 app-title">Ozzie Lovin</h1>
      <div class="h-100 d-flex flex-column justify-content-center align-content-center">
        <div class="row justify-content-center">
          <div class="col-7">
            <form method="post" @submit="handleSubmit">
              <p class="error" v-if="message">{{ this.message }}</p>
              <input type="email" placeholder="Email" class="form-control form-input mt-2 text-white"
                     name="email" v-model="email" :class="{'is-invalid': this.errors.email}"/>
              <div v-if="this.errors.email" id="validationEmail" class="invalid-feedback">
                {{ errors.email }}
              </div>

              <input type="password" placeholder="Password" class="form-control form-input mt-2 text-white"
                     name="password" v-model="password" v-on:keyup.enter="handleSubmit" :class="{'is-invalid': this.errors.password}"/>
              <div v-if="this.errors.password" id="validationPassword" class="invalid-feedback">
                {{ errors.password }}
              </div>

              <div class="d-flex justify-content-center" style="margin-top: 2rem">
                <button class="btn btn-secondary btn-style1" :disabled="loading">Create</button>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <img src="../assets/imgs/lion.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {auth} from "@/firebase";
import firebase from "firebase";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      message: "",
      errors: {}
    }
  },
  methods: {
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      this.errors = {}
      if (!this.email) {
        this.errors.email = 'Email required.';
      } else if (!this.validEmail(this.email)) {
        this.errors.email = 'Valid email required.';
      }

      if (!this.password) {
        this.errors.password = "Password required.";
      }

      return Object.keys(this.errors).length == 0 ? true: false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.message = "";
      let that = this;
      if (this.checkForm()) {
        this.loading = true;
        auth.createUserWithEmailAndPassword(this.email, this.password).then(async (userCredential) => {
          console.log(userCredential.user)
          await that.$store.dispatch('createUser', userCredential.user);
          await firebase.auth().signOut();
          this.loading = false;
          this.$router.replace('/login');
        }).catch((error) => {
          this.message = error.message
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
